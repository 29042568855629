@font-face {
  font-family: Exo-Regular;
  src: url(./sources/fonts/Exo-Regular.ttf);
}

@font-face {
  font-family: Exo-SemiBold;
  src: url(./sources/fonts/Exo-SemiBold.ttf);
}

@font-face {
  font-family: Exo-VariableFont_wght;
  src: url(./sources/fonts/Exo-VariableFont_wght.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #7f8a94;
  display: flex;
  width: 100%;
  height: 100vh;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  background-color: #7f8a94;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: #000;
  background-image: url('sources/images/main-background.png');
  background-position: center;
  background-size: cover;
}

button {
  padding: 0;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
