.container {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .imageBlock {
        max-width: 515px;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .connectionBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width: 515px;
        max-height: 515px;
        height: 48vh;
        min-height: 350px;
        margin-top: 20px;
        padding: 50px 80px;
        background-color: #031529;
        border-radius: 20px;
        font-family: 'Exo-Regular';

        .topBlock {
            width: 100%;

            .title {
                font-family: 'Exo-SemiBold';
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                color: #91DBFF;
                text-align: center;
                margin-top: 0;
            }

            .walletBlock {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    margin: 0;
                    color: #00FF00;
                    font-size: 12px;
                    font-family: 'Exo-Regular';
                    font-weight: 600;
                    letter-spacing: 0.02em;
                }

                .connectFailure {
                    color: red;
                }

                .walletInfo {
                    margin: 5px 0 15px;
                    position: relative;
                    height: 35px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .addressField {
                        position: absolute;
                        height: 35px;
                        width: 100%;
                    }

                    .copyBtn {
                        cursor: pointer;
                        height: 33px;
                        width: 33px;
                        z-index: 1000;
                    }

                    div {
                        font-family: 'Exo-Regular';
                        font-weight: 600;
                        color: white;
                        z-index: 1000;
                        margin: 0 11px 0 8px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .address {
                        font-size: 11px;
                        color: #91DBFF;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .buttonsBlock {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            .btn {
                margin-top: 6px;
                position: relative;
                height: 55px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                color: white;
                border: none;
                outline-color: transparent;
                outline-style: none;
                cursor: pointer;
                background-color: #031529;

                img {
                    position: absolute;
                    height: 55px;
                    width: 100%;
                }

                span {
                    margin-bottom: 3px;
                    z-index: 1000;
                    font-size: 14px;
                    font-weight: 600;
                }

            }

            .btnDisconnect {
                img {
                    height: 48px;
                    width: 98%;
                }
            }
        }
    }
}

