.App {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.App > img {
  position: absolute;
  top: 50px;
  left: 50px;
  cursor: pointer;
}
